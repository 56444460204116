import * as React from 'react';
import Box from '@lce/slice_v2/Layout/Box';
import Flex from '@lce/slice_v2/Layout/Flex';
import Container from '@lce/intl-ui/src/Layout/Container';
import Map from '@lce/intl-ui/src/PageComponents/ContactUs/Map';
import PageTitle from '@lce/intl-ui/src/Elements/PageTitle';
import StoreLocationsList from '@lce/intl-ui/src/PageComponents/ContactUs/StoreLocationsList';
import { Direction } from '@lce/intl-types/src/IDirection';
import MapIdProvider from '@lce/intl-util/src/api/SelectedMapId/context/MapIdProvider';

import { IMapDisplayProps } from './Map/components/MarkerLoader';
import {
  ILocationListDefaultProps,
  IStoreLocationProps,
} from './StoreLocationsList/components/StoreLocation/StoreLocation';

export interface IContactUs {
  locationDefault: ILocationListDefaultProps;
  mapDisplay: IMapDisplayProps;
  locationsList: IStoreLocationProps[];
  googleMapKey: string;
  direction: Direction;
  legalText: string;
}
const ContactUsPage: React.FC<IContactUs> = ({ locationDefault, mapDisplay, locationsList, googleMapKey, direction, legalText }) => (
  <MapIdProvider>
    <Container sx={{ px: '16px' }}>
      <PageTitle>{locationDefault.pageTitle}</PageTitle>
      <Flex sx={{
        flexDirection: ['column', 'row'],
        justifyContent: 'space-between',
        pb: '1.5em',
      }}>
        <Map apiKey={googleMapKey} {...mapDisplay} />
        <Box sx={{ width: ['100%', '50%'] }}>
          <StoreLocationsList direction={direction} locationDefault={locationDefault} locations={locationsList} />
        </Box>
      </Flex>
      { legalText && <Box dangerouslySetInnerHTML={{ __html: legalText }} sx={{ fontFamily: 'tertiary', fontSize: '16px' }} /> }
    </Container>
  </MapIdProvider>
);

export default ContactUsPage;
