import * as React from 'react';
import { sortBy as _sortBy, map as _map } from 'lodash';
import Box from '@lce/slice_v2/Layout/Box';

import { Direction } from '@lce/intl-types/src/IDirection';
import StoreLocation, {
  ILocationListDefaultProps,
  IStoreLocationProps,
} from './components/StoreLocation/StoreLocation';

export interface IStoreLocationsProps {
  locations: IStoreLocationProps[];
  locationDefault: ILocationListDefaultProps;
  direction: Direction;
}

const StoreLocationsList: React.FC<IStoreLocationsProps> = ({ locations, locationDefault, direction }) => (
  <Box
    data-testid="LocationList"
    sx={{
      p: [null, direction === 'rtl' ? '0 88px 0 20px' : '0 20px 0 88px'],
      pt: '20px',
      width: '100%',
      height: 'auto',
    }}
  >
    {_map(locationDefault.isSorted ? _sortBy(locations, 'name') : locations, (location, index) => (
      <StoreLocation data-testid={location.name.replace(/\s+/g, '-')} key={index} {...location} {...locationDefault} />
    ))}
  </Box>
);

export default StoreLocationsList;
